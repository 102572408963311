import React from 'react'
import Layout from "./layout/layout";
import Link from "next/link";
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
export default function Page404() {
    return (
        <Layout isSubscribe={false}>
            <div className={'error-page'}>
                <div className={'error-page__inner'}>
                    <div className={'error-page__number'}>404</div>
                    <div className={'error-page__msg'}>Sorry, it looks like there was an error.</div>
                    <div className={'text-center'}>
                        <Link href={'/'}>
                            <Button variant={'danger'} className={'bg-red no-border error-page__back-btn'}>
                                {/*<span className={'fa fa-chevron-left me-1 fa-fw'}></span>*/}
                                <FontAwesomeIcon icon={faChevronLeft} fixedWidth={true} className={'me-1'} />
                                Go back home
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
